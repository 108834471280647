<mat-card class="flex-100 chart-dock">
  <mat-card-header>
    <mat-card-title>
      <div class="flex flex-row">
        <div class="flex">
          {{ form.get('title')?.value }}
        </div>
      </div>
    </mat-card-title>
  </mat-card-header>
  <mat-card-content class="flex flex-row flex-wrap">
    <form
      [formGroup]="form"
      class="flex flex-100 flex-row flex-wrap justify-between align-center"
    >
      <mat-form-field
        appearance="outline"
        class="flex flex-100 flex-gt-sm-47 chart-select"
      >
        <mat-label>Select Chart</mat-label>
        <mat-select
          formControlName="active"
          (selectionChange)="onSelectionChanged($event)"
        >
          <mat-option *ngFor="let chart of charts" [value]="chart.id">
            {{ chart.title }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </form>
    <div [ngSwitch]="chartType" class="flex-100">
      <msep-line-chart
        *ngSwitchCase="lineChart"
        [partnerId]="partnerId"
        [lineChartId]="chartId"
      ></msep-line-chart>
      <msep-spouse-engagement-multi-line-chart
      *ngSwitchCase="spouseEngagementMultiLineChart"
        [partnerId]="partnerId"
      ></msep-spouse-engagement-multi-line-chart>
      <msep-total-published-jobs-multi-line-chart
        *ngSwitchCase="totalPublishedJobsMultiLineChart"
        [partnerId]="partnerId"
      ></msep-total-published-jobs-multi-line-chart>
      <msep-pie-chart
        *ngSwitchCase="pieChart"
        [partnerId]="partnerId"
        [pieChartType]="chartId"
      ></msep-pie-chart>
    </div>
  </mat-card-content>
</mat-card>
