import { Component, Input, OnDestroy } from '@angular/core';
import { LineChart } from 'core/enums';
import { BlobService } from 'core/services/blob.service';
import {
  ChartsService,
  LineChartMetricsFilter,
} from 'core/services/charts.service';
import { NotificationService } from 'core/services/infrastructure/notification.service';
import { SecoService } from 'core/services/partner/seco.service';
import { Observable, Subscription } from 'rxjs';
import { ServerBlobResult } from 'shared/models/server-blob-result';

@Component({
  selector: 'msep-export-line-chart-button',
  templateUrl: './export-line-chart-button.component.html',
})
export class ExportLineChartButtonComponent implements OnDestroy {
  @Input() chartId!: LineChart;
  @Input() organizationId?: number;
  @Input() filters!: LineChartMetricsFilter;

  isLoading = false;
  chartsObservable$!: Observable<ServerBlobResult>;

  private subscriptions: Subscription[] = [];

  constructor(
    private blobService: BlobService,
    private chartsService: ChartsService,
    private notificationService: NotificationService,
    private secoService: SecoService
  ) {}

  onExportClick(): void {
    this.isLoading = true;
    this.setObservable();
    const subscription = this.chartsObservable$.subscribe({
      next: result => {
        this.blobService.download(result);
      },
      error: () => {
        this.notificationService.emitFailure(
          'Error exporting chart data. Please contact an administrator'
        );
        this.isLoading = false;
      },
      complete: () => {
        this.isLoading = false;
      },
    });

    this.subscriptions.push(subscription);
  }

  ngOnDestroy(): void {
    this.subscriptions?.forEach(x => x.unsubscribe());
  }

  private setObservable(): void {
    switch (this.chartId) {
      case LineChart.CandidateSearch:
        this.setCandidateSearchObservable();
        break;
      case LineChart.PartnersWhoReported:
        this.setPartnersWhoReportedObservable();
        break;
      case LineChart.SpousesHired:
        this.setSpouseHiredObservable();
        break;
      case LineChart.SpousesCurrentlyEmployed:
        this.setSpouseCurrentlyEmployedObservable();
        break;
      case LineChart.SpouseEngagement:
        this.setSpouseEngagementObservable();
        break;
      case LineChart.TotalJobs:
        this.setTotalJobsObservable();
        break;
      case LineChart.TotalPartners:
        this.setTotalPartnersObservable();
        break;
    }
  }

  private setCandidateSearchObservable(): void {
    this.chartsObservable$ = this.chartsService.exportCandidateSearchMetrics(
      this.filters,
      this.organizationId
    );
  }

  private setPartnersWhoReportedObservable(): void {
    this.chartsObservable$ =
      this.chartsService.exportPartnersWhoReportedMetrics(this.filters);
  }

  private setSpouseHiredObservable(): void {
    this.chartsObservable$ = this.chartsService.exportSpouseHiredMetrics(
      this.filters,
      this.organizationId
    );
  }

  private setSpouseCurrentlyEmployedObservable(): void {
    this.chartsObservable$ =
      this.chartsService.exportSpouseCurrentlyEmployedMetrics(
        this.filters,
        this.organizationId
      );
  }

  private setSpouseEngagementObservable(): void {
    this.chartsObservable$ = this.secoService.exportSpouseEngagementChartData(
      this.filters
    );
  }

  private setTotalJobsObservable(): void {
    this.chartsObservable$ = this.chartsService.exportTotalJobsMetrics(
      this.filters
    );
  }

  private setTotalPartnersObservable(): void {
    this.chartsObservable$ = this.chartsService.exportTotalPartnersMetrics(
      this.filters
    );
  }
}
