<msep-dock
  title="Partner Engagement"
  [dockId]="partnerEngagement"
  [isCollapsed]="isCollapsed"
  (toggleCollapseDock)="toggleCollapse()"
>
  <ng-container
    *ngIf="partnerEngagement$ | async as partnerMetrics; else loading"
  >
    <div class="flex flex-row justify-between align-center multiple-dock-items">
      <strong>Total Candidate Searches</strong>
      <div class="count default">
        {{ partnerMetrics.candidateSearchCount | number }}
      </div>
    </div>
    <mat-divider></mat-divider>
    <div class="flex flex-row justify-between align-center multiple-dock-items">
      <strong>Total Monthly Reports Completed</strong>
      <div class="count default">
        {{ partnerMetrics.completedMonthlyReportCount | number }}
      </div>
    </div>
    <mat-divider></mat-divider>
    <div class="flex flex-row justify-between align-center multiple-dock-items">
      <strong>Partners with 4+1 Badge</strong>
      <div class="count default">
        {{ partnerMetrics.fourPlusOnePartnerCount | number }}
      </div>
    </div>
    <mat-divider></mat-divider>
  </ng-container>
  <ng-container *ngIf="partnerTracker$ | async as partnerTracker; else loading">
    <div class="flex flex-row justify-between align-center multiple-dock-items">
      <strong>Highly Engaged</strong>
      <div class="count green">
        {{ partnerTracker.highlyEngaged | number }}
      </div>
    </div>
    <mat-divider></mat-divider>
    <div class="flex flex-row justify-between align-center multiple-dock-items">
      <strong>Opportunity to Increase Engagement</strong>
      <div class="count yellow">
        {{ partnerTracker.increaseEngagement | number }}
      </div>
    </div>
    <mat-divider></mat-divider>
    <div class="flex flex-row justify-between align-center multiple-dock-items">
      <strong>Limited Demonstration of Engagement</strong>
      <div class="count orange">
        {{ partnerTracker.limitedEngagement | number }}
      </div>
    </div>
    <mat-divider></mat-divider>
    <div class="flex flex-row justify-between align-center multiple-dock-items">
      <strong>No Engagement</strong>
      <div class="count red">
        {{ partnerTracker.noEngagement | number }}
      </div>
    </div>
  </ng-container>
</msep-dock>
<ng-template #loading>
  <msep-centered-loader></msep-centered-loader>
</ng-template>
