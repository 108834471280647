import { DatePipe } from '@angular/common';
import {
  AfterViewInit,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatSelectChange } from '@angular/material/select';
import { Chart } from 'chart.js/auto';
import { LineChart } from 'core/enums';
import {
  ChartsService,
  LineChartMetricsFilter,
  TotalJobsLineChartMetrics,
} from 'core/services/charts.service';
import { Resource, ResourcesService } from 'core/services/resources.service';
import moment from 'moment';
import { BehaviorSubject, Observable, delay, switchMap, tap } from 'rxjs';
import { ApiResponse } from 'shared/models/api-response';

@Component({
  selector: 'msep-total-published-jobs-multi-line-chart',
  templateUrl: './total-published-jobs-multi-line-chart.component.html',
  styleUrls: ['./total-published-jobs-multi-line-chart.component.scss'],
})
export class TotalPublishedJobsMultiLineChartComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  @Input() partnerId?: number;

  chart!: Chart;
  chartId!: string;
  chartTitle!: string;
  dateFormat = 'MM-dd-yyyy';
  form!: UntypedFormGroup;
  isLoading = false;
  partnershipTypes$ = this.resourceService.getPartnershipTypes();
  selectedEndDate!: Date;
  selectedStartDate!: Date;
  totalJobsLineChartMetrics!: TotalJobsLineChartMetrics[];
  totalJobsLineChartMetrics$!: Observable<
    ApiResponse<TotalJobsLineChartMetrics[]>
  >;
  totalJobsLineChartMetricsAction$!: Observable<LineChartMetricsFilter>;

  totalJobsChartId = LineChart.TotalJobs;

  defaultFilter = {
    organizationId: undefined,
    partnershipTypeId: undefined,
    startDate: '',
    endDate: '',
  } as LineChartMetricsFilter;

  private totalJobsMetricsSubject = new BehaviorSubject<LineChartMetricsFilter>(
    this.defaultFilter
  );

  constructor(
    private chartsService: ChartsService,
    private formBuilder: UntypedFormBuilder,
    private resourceService: ResourcesService
  ) {}

  ngOnInit(): void {
    this.defaultFilter.organizationId = this.partnerId;
    this.setChartValues();
    this.setDateFilterDefaults();
    this.buildForm();
    this.loadTotalJobsChartData();
  }

  ngAfterViewInit(): void {
    this.createChart();
  }

  get currentFilter(): LineChartMetricsFilter {
    return {
      ...this.form.value,
    };
  }

  onEndDateSelected(date: Date): void {
    const endDate = moment(date).endOf('month').toDate();
    const selectedDate = new DatePipe('en-US').transform(
      endDate,
      this.dateFormat
    );

    if (!selectedDate) return;

    this.form.patchValue({
      endDate: selectedDate,
    });
    this.totalJobsMetricsSubject.next(this.currentFilter);
  }

  onOrganizationSelected(organization: Resource): void {
    this.form.patchValue({
      organizationId: organization.id,
      organizationName: organization.description,
    });

    this.totalJobsMetricsSubject.next(this.currentFilter);
  }

  onPartnershipTypeChanged(event: MatSelectChange): void {
    this.form.patchValue({
      partnershipTypeId: event.value,
    });
    this.totalJobsMetricsSubject.next(this.currentFilter);
  }

  onStartDateSelected(date: Date): void {
    const startDate = moment(date).toDate();
    startDate.setHours(0, 0, 0, 0);
    startDate.setDate(1);
    const selectedDate = new DatePipe('en-US').transform(
      startDate,
      this.dateFormat
    );

    if (!selectedDate) return;

    this.form.patchValue({
      startDate: selectedDate,
    });
    this.totalJobsMetricsSubject.next(this.currentFilter);
  }

  onReset(): void {
    this.form.patchValue({
      organizationId: this.defaultFilter.organizationId,
      organizationName: null,
      partnershipTypeId: this.defaultFilter.partnershipTypeId,
      startDate: this.defaultFilter.startDate,
      endDate: this.defaultFilter.endDate,
    });
    this.setDateFilterDefaults();
    this.totalJobsMetricsSubject.next(this.currentFilter);
  }

  private buildForm(): void {
    this.form = this.formBuilder.group({
      organizationId: this.defaultFilter.organizationId,
      organizationName: null,
      partnershipTypeId: this.defaultFilter.partnershipTypeId,
      startDate: this.defaultFilter.startDate,
      endDate: this.defaultFilter.endDate,
    });
  }

  private createChart(): void {
    this.chart = new Chart(this.chartId, {
      type: 'line',
      data: {
        labels: [],
        datasets: [
          {
            data: [],
            fill: false,
            borderColor: '#3d9ce0',
            backgroundColor: '#3d9ce0',
          },
          {
            data: [],
            fill: false,
            borderColor: '#8b5bb0',
            backgroundColor: '#8b5bb0',
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: true,
        layout: {
          padding: {
            left: 50,
            right: 50,
            bottom: 20,
          },
        },
        plugins: {
          legend: {
            display: true,
            position: 'bottom',
          },
        },
        scales: {
          y: {
            beginAtZero: true,
          },
        },
      },
    });
  }

  private loadTotalJobsChartData(): void {
    this.totalJobsLineChartMetricsAction$ =
      this.totalJobsMetricsSubject.asObservable();

    this.totalJobsLineChartMetrics$ =
      this.totalJobsLineChartMetricsAction$.pipe(
        delay(0),
        tap(() => {
          this.isLoading = true;
        }),
        switchMap(filters => {
          return this.chartsService.getTotalJobsMetrics(filters);
        }),
        tap(results => {
          this.totalJobsLineChartMetrics = results.data;
          this.updateChart();
          this.isLoading = false;
        })
      );
  }

  private setChartValues(): void {
    this.chartId = 'totalJobsChart';
    this.chartTitle = 'Total Number of Published Jobs';
  }

  private setDateFilterDefaults(): void {
    const today = new Date();
    this.selectedEndDate = new Date(
      today.getFullYear(),
      today.getMonth() + 1,
      1
    );
    today.setDate(today.getDate() - 365);
    this.selectedStartDate = new Date(today.getFullYear(), today.getMonth(), 1);

    this.defaultFilter.startDate =
      new DatePipe('en-US').transform(
        this.selectedStartDate,
        this.dateFormat
      ) ?? '';
    this.defaultFilter.endDate =
      new DatePipe('en-US').transform(this.selectedEndDate, this.dateFormat) ??
      '';
  }

  private updateChart(): void {
    this.chart.data.labels = [];
    this.chart.data.labels = this.totalJobsLineChartMetrics.map(x =>
      new DatePipe('en-US').transform(x.date, 'yyyy-MM')
    );

    this.chart.data.datasets[0].data = this.totalJobsLineChartMetrics.map(
      x => x.feedCount
    );
    this.chart.data.datasets[0].label = 'Total jobs from feeds';

    this.chart.data.datasets[1].data = this.totalJobsLineChartMetrics.map(
      x => x.manualCount
    );
    this.chart.data.datasets[1].label = 'Total manual jobs';

    this.chart.update();
  }

  ngOnDestroy(): void {
    this.chart?.destroy();
  }
}
